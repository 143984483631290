export interface Subscriber {
	notify(event:any):void
	arg?:any
}

export interface Subscription {
	unsubscribe():void;
	subscriber():Subscriber
}

export default class Observer<Event> {
	constructor() {}

	protected subscribers:Subscriber[] = [];

	protected filter(event:Event):Subscriber[] {
		return this.subscribers;
	}

	protected eventListener(event:Event):void {
		if (this.subscribers.length) this.publish(event);
	}

	public subscribe(notify:(event:Event)=>void, arg?:any):Subscription {
		this.subscribers.push({notify, arg});

		return { 
		unsubscribe: ():void => this.unsubscribe.call(this, notify, arg),
			subscriber: () => ({ notify, arg })
		};
	}

	public unsubscribe(notify:((event:Event)=>void) | '*', arg?:any):void {
		this.subscribers = this.subscribers.filter(subscriber => {
			if (typeof notify == 'function' && !arg) return subscriber.notify != notify;
			if (typeof notify == 'function' && arg) return subscriber.notify != notify || subscriber.arg != arg;
			if (notify == '*' && arg) return subscriber.arg != arg;
			return false;
		});
	}

	public publish(event:Event):void {
		this.filter(event).forEach(subscriber => subscriber.notify(event));
	}
}