import { CookieStorage } from "cookie-storage";

interface StorageData<T = any> {
  value: T;
  expiryDate: Date;
} 

export interface IStorageProvider {
  addItem: <T = any>(name: string, value: T, expiryDate: Date) => void;
  getItem: <T = any>(name: string) => T | undefined;
}


export class StorageProvider implements IStorageProvider {
	constructor(private readonly prefix: string = "dentolo_") {}

  private cookieStorage = new CookieStorage();

  public addItem<T = any>(name: string, value: T, expiryDate: Date) {
    if (this.isLocalStorageSupported()) {
			window.localStorage.setItem(this.prefix + name, JSON.stringify({ value, expiryDate }));
		} else {
      this.cookieStorage.setItem(this.prefix + name, JSON.stringify(value), { expires: expiryDate });
    }
  }

  public getItem<T = any>(name: string): T | undefined {
    if (this.isLocalStorageSupported()) {
      try {
        const item = JSON.parse(String(window.localStorage.getItem(this.prefix + name))) as StorageData<T>;
        if (item.value !== undefined && item.expiryDate) {
          if (new Date(item.expiryDate).getTime() > new Date().getTime()) return item.value;
        }
  
        window.localStorage.removeItem(name);
        return undefined;
      } catch(e) { 
        return undefined 
      };
    } else {
      try {
        const item = JSON.parse(String(this.cookieStorage.getItem(this.prefix + name))) as T;
        return item;
      } catch(e) {
        return undefined;
      }
      
    }

  }

	private isLocalStorageSupported() {
		try {
			const testKey = "__some_random_key_you_are_not_going_to_use__";
			window.localStorage.setItem(testKey, testKey);
			window.localStorage.removeItem(testKey);
			return true;
		} catch (e) {
			console.warn('localStorage is disabled');
			return false;
		}
	}
}