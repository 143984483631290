import Observer from '../models/observer.model';

export interface PostMessageData {
	access: string
	type:string,
	payload: any
}

class PostMessageDispatcher extends Observer<PostMessageData> {
	constructor() { 
		super(); 
		window.addEventListener("message", ({data}) => {
			try {
				var postMessageData:PostMessageData = JSON.parse(data);
				this.eventListener(postMessageData);
			} catch(e) { return }
		});
	}

	protected eventListener(data:PostMessageData) {
		if (data.access === 'dentolo') super.eventListener(data);
	}

	public send(recepient:Window | null | undefined, type:string, payload?:any):void {
		if (!recepient) return;
		recepient.postMessage(JSON.stringify({ access: 'dentolo', type, payload }), '*');
	}
}

export default new PostMessageDispatcher();