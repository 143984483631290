import device from 'current-device';
import { Service } from '../../constants';
import postMessageDispatcher from '../../dispatchers/post-message.dispatcher';
import EventEmitter from '../event-emitter.model';
import { DentoloEventName } from '../../constants';
import styles from './iframe.module.scss';
import { Dentolo } from '../../dentolo';

export default class Iframe extends EventEmitter {
	private iframe: HTMLIFrameElement = document.createElement('iframe');
	private wrapper:HTMLDivElement = document.createElement('div');
	private mainElement:HTMLElement;
	private loaded:boolean = false;
	private opened:boolean = false;

	constructor(public url:string, public clientId:string, public serviceList:Service[], private scenary:Dentolo.Scenario, iframeSelector?: string) {
		super()

		this.iframe.title = "Dentolo Quiz";
    
    if (scenary == "custom" && iframeSelector) {
      this.iframe = document.querySelector<HTMLIFrameElement>(iframeSelector) || this.iframe;
      this.iframe.classList.add(styles.custom); 
      this.mainElement = this.iframe;
      
    } else {

      this.iframe.className = styles.default;
      this.iframe.name = 'dentolo-iframe';
      this.mainElement = this.iframe;

      if (device.ios()) {
        this.iframe.className = styles.iframe;
        this.wrapper.className = styles.wrapper;
        this.wrapper.appendChild(this.iframe);
        this.mainElement = this.wrapper;
      }

      if (scenary == 'fullPage') this.mainElement.classList.add(styles.fullPage);

    }
    
    postMessageDispatcher.subscribe(({type, payload}) => {
      if (type == "load_quiz") {
        this.window = this.iframe.contentWindow;
        this.loaded = true;
      }
      this.emit(type, payload);
    });
	}
	
	public window:Window | null;

	public isLoaded():boolean {
		return this.loaded;
	}

	public isOpened():boolean {
		return this.opened;
	}

	public appendToPage():Promise<void> {
		return new Promise<void>(resolve => {
			if (/complete|interactive|loaded/.test(document.readyState)) {
				document.body.appendChild(this.mainElement);
				resolve();
			} else {
				document.addEventListener("DOMContentLoaded", () => {
					document.body.appendChild(this.mainElement)
					resolve();
				});
			}
		});
	}

	public loadContent():Promise<void> {
		return new Promise((resolve, reject) => {
			this.iframe.src = this.url;
			this.window = this.iframe.contentWindow;

			this.subscribe(DentoloEventName.LOAD_QUIZ, () => {
				this.loaded = true;
				resolve();
			});
		});
	}

	public show():void {
    if (this.scenary == "custom") return;

		this.mainElement.classList.add(styles.active);
		setTimeout(() => {
			this.mainElement.classList.add(styles.visible);
      this.iframe.style.height = "100%";
			this.opened = true;
		}, 100);
	}

	public hide():void {
    if (this.scenary == "custom") return;

		this.mainElement.classList.remove(styles.visible);
		setTimeout(() => {
			this.mainElement.classList.remove(styles.active);
			this.opened = false;
		}, 400);
	}

	public send(eventName:string, payload:object) {
		postMessageDispatcher.send(this.window, eventName, payload);
	}
}