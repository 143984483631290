import modificators from "./modificators.json";

const successCombination = [0,0,1,1,1,2,0,0,0,1,1,0,2,1,1,1,0,0,0];
const defaultCombination = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];

export async function getCombination(comb: number) {
  const combination = comb == 1 ? successCombination : defaultCombination;
  return modificators.map(mod => {
    return {
      name: mod.name,
      order: mod.order,
      variant: mod.variants[combination[mod.order]]
    }
  });
}