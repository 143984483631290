import { Dentolo } from './dentolo';
import { Service } from './constants';

export function checkServiceListForWrong(list:Dentolo.Service[]): boolean {
  const services = (Object.keys(Service) as Array<keyof typeof Service>).map<Dentolo.Service>((key: keyof typeof Service) => Service[key]);
  
  for (let item of list) {
    if (services.includes(item)) {
      continue;
    } else return false;
  }

  return true;
}

function typeErrorMessage(propName:string, expectedType:string, gotType:string):string {
	return `Property ${propName} must be of type: ${expectedType}, but got type: ${gotType}`;
}

export function checkOptionsForErrors(options:Dentolo.Options):void | never {
	const scenarioForbiddenOptions:Array<keyof Dentolo.Options> = ['widget', 'autopopupTimeout', 'openOnScroll'];
	const scenarioType:string = "'popup' | 'fullPage' | undefined";

	if (typeof options.clientId !== 'string') {
		throw new TypeError(typeErrorMessage('clientId', 'string', typeof options.clientId));
	} else if (options.clientId == '') {
		throw new Error('Property clientId cannot be an empty string');
	}

	if (!(options.serviceList instanceof Array) && options.serviceList !== undefined) {
		throw new TypeError(typeErrorMessage('serviceList', 'Array<Service>', typeof options.serviceList));
	}

	if ((options.serviceList instanceof Array) && !options.serviceList.length) {
		throw new Error('Array serviceList cannot be empty');
	}

	if (options.serviceList instanceof Array) {
		if (!checkServiceListForWrong(options.serviceList as Service[])) {
      throw new Error(`Property serviceList contains incompatible value: [${options.serviceList.map(item => '"' + String(item) + '"').join(", ")}]`);
    }
	}

	if (typeof options.autopopupTimeout !== 'number' && options.autopopupTimeout !== undefined) {
		throw new TypeError(typeErrorMessage('autopopupTimeout', 'number | undefined', typeof options.autopopupTimeout));
	}

	if (typeof options.initialPageIndex !== 'number' && options.initialPageIndex !== undefined) {
		throw new TypeError(typeErrorMessage('initialPageIndex', 'number | undefined', typeof options.initialPageIndex));
	}

	if (typeof options.openOnScroll !== 'boolean' && options.openOnScroll !== undefined) {
		throw new TypeError(typeErrorMessage('openOnScroll', 'boolean | undefined', typeof options.openOnScroll));
	}

	if (typeof options.scenario !== 'string' && options.scenario !== undefined) {
		throw new TypeError(typeErrorMessage('scenario', scenarioType, typeof options.scenario));
	}

	if (typeof options.scenario == 'string' && options.scenario !== 'popup' && options.scenario !== 'fullPage' && options.scenario !== 'custom') {
		throw new TypeError(`Value '${options.scenario}' of option scenario is incompatible with type: ${scenarioType}`);
	}

	if (options.scenario == 'fullPage') {
		scenarioForbiddenOptions.forEach(option => {
			 if (options[option] != undefined) {
				throw new Error(`Option ${option} is forbidden when scenario is set 'fullPage'. Remove ${option} from options or set it to undefined`);
			}
		});
	}
}

export function checkWidgetOptionsForErrors(widgetOptions:Dentolo.WidgetOptions):void | never {
	const { mode, wideWidgetStyle, zIndex, hideOnOpenQuiz, showMiniatureOnHide, wideButtonOnMobiles, miniWidgetPosition } = widgetOptions;
	
	const modeType = "'wide' | 'mini' | undefined";
	const alignType = "'left' | 'right' | 'center'";
	const wideWidgetStyleType = "'default' | 'compact' | 'offset-to-left' | undefined";
	const miniWidgetPositionType = "'right-bottom' | 'left-bottom' | 'right-top' | 'left-top'";
	const miniWidgetPositionUnitType = "[px' | 'em' | 'rem' | '%' | 'vw'";

	if (typeof mode !== 'string' && mode !== undefined) {
		throw new TypeError(typeErrorMessage('widget.mode', modeType, typeof mode));
	} 

	if (typeof mode == 'string' && mode != 'wide' && mode != 'mini') {
		throw new TypeError(`Value '${mode}' of property widget.mode is incompatible with type: ${modeType}`)
	}

	if (typeof wideWidgetStyle !== 'string' && wideWidgetStyle !== undefined) {
		throw new TypeError(typeErrorMessage('widget.wideWidgetStyle', wideWidgetStyleType, typeof wideWidgetStyle));
	} 

	if (typeof wideWidgetStyle == 'string' && wideWidgetStyle != 'default' && wideWidgetStyle != 'compact' && wideWidgetStyle != 'offset-to-left') {
		throw new TypeError(`Value '${wideWidgetStyle}' of property widget.wideWidgetStyle is incompatible with type: ${wideWidgetStyleType}`)
	}

	if (typeof zIndex !== 'number' && zIndex !== undefined) {
		throw new TypeError(typeErrorMessage('options.widget.zIndex', 'number | undefined', typeof zIndex));
	}

	if (typeof hideOnOpenQuiz !== 'boolean' && hideOnOpenQuiz !== undefined) {
		throw new TypeError(typeErrorMessage('options.widget.hideOnOpenQuiz', 'boolean | undefined', typeof hideOnOpenQuiz));
	}

	if (typeof showMiniatureOnHide !== 'boolean' && showMiniatureOnHide !== undefined) {
		throw new TypeError(typeErrorMessage('options.widget.showMiniatureOnHide', 'boolean | undefined', typeof showMiniatureOnHide));
	}

	if (typeof wideButtonOnMobiles !== 'object' && wideButtonOnMobiles !== undefined) {
		throw new TypeError(typeErrorMessage('options.widget.wideButtonOnMobiles', 'object', typeof wideButtonOnMobiles));
	}

	if (typeof wideButtonOnMobiles?.align !== 'string' && wideButtonOnMobiles?.align !== undefined) {
		throw new TypeError(typeErrorMessage('options.widget.wideButtonOnMobiles.align', alignType, typeof wideButtonOnMobiles?.align));
	}

	if (typeof wideButtonOnMobiles?.align === 'string' && wideButtonOnMobiles?.align !== 'left' && wideButtonOnMobiles?.align !== 'right' && wideButtonOnMobiles?.align !== 'center') {
		throw new TypeError(`Value '${wideButtonOnMobiles?.align}' of option align is incompatible with type: ${alignType}`);
	}

	if (typeof wideButtonOnMobiles?.width !== 'string' && wideButtonOnMobiles?.width !== undefined) {
		throw new TypeError(typeErrorMessage('options.widget.wideButtonOnMobiles.width', 'string', typeof wideButtonOnMobiles?.width));
	}

	if (typeof wideButtonOnMobiles?.text !== 'string' && wideButtonOnMobiles?.text !== undefined) {
		throw new TypeError(typeErrorMessage('options.widget.wideButtonOnMobiles.text', 'string', typeof wideButtonOnMobiles?.text));
	}

	if (typeof miniWidgetPosition !== 'string' && typeof miniWidgetPosition !== 'object' && miniWidgetPosition !== undefined) {
		throw new TypeError(typeErrorMessage('options.widget.miniWidgetPosition', 'MiniWidgetPosition | MiniWidgetCustomPosition', typeof miniWidgetPosition));
	}

	if (typeof miniWidgetPosition == 'string') {
		if (miniWidgetPosition != 'right-bottom' && miniWidgetPosition != 'right-top' && miniWidgetPosition != 'left-bottom' && miniWidgetPosition != 'left-top') {
			throw new TypeError(`Value '${miniWidgetPosition}' of property widget.miniWidgetPosition is incompatible with type: ${miniWidgetPositionType}`);
		}
	}

	if (typeof miniWidgetPosition == 'object') {
		if (!miniWidgetPosition.unit) throw new TypeError(`property "unit" is required in miniWidgetPosition if it is type of MiniWidgetCustomPosition`);
		if (!miniWidgetPosition.x) throw new TypeError(`property "x" is required in miniWidgetPosition if it is type of MiniWidgetCustomPosition`);
		if (!miniWidgetPosition.y) throw new TypeError(`property "y" is required in miniWidgetPosition if it is type of MiniWidgetCustomPosition`);

		if (miniWidgetPosition.unit != 'px' && miniWidgetPosition.unit != 'em' && miniWidgetPosition.unit != '%' && miniWidgetPosition.unit != 'rem' && miniWidgetPosition.unit != 'vw') {
			throw new TypeError(`Value '${miniWidgetPosition.unit}' of property miniWidgetPosition.unit is incompatible with type: ${miniWidgetPositionUnitType}`);
		}

		if (typeof miniWidgetPosition.x != 'number') {
			throw new TypeError(typeErrorMessage('miniWidgetPosition.x', 'number', typeof miniWidgetPosition.x));
		}

		if (typeof miniWidgetPosition.y != 'number') {
			throw new TypeError(typeErrorMessage('miniWidgetPosition.y', 'number', typeof miniWidgetPosition.y));
		}
	}
}