import Observer from '../models/observer.model';

class ScrollEmitter extends Observer<Event> {
	constructor() { 
		super()
		window.addEventListener('scroll', this.eventListener.bind(this));
	}

	public unsubscribe(notify:(event:Event)=>void):void {
		return super.unsubscribe(notify);
	}
}

export default new ScrollEmitter();