import { appConfig } from "./config/app.config";
import EventEmitter from "./models/event-emitter.model";

export class YaMetrikaCounter {
  constructor(
    private readonly metrika_number: number
  ) { }

  private ym: any;
	private _inited: boolean = false;
	private eventEmitter = new EventEmitter();

	public isInited(): boolean {
		return this._inited;
	}

  public async init(webvisor: boolean = false) {
    const SRC = "https://mc.yandex.ru/metrika/tag.js";
    const $this = this;

    this.ym = window.ym || function () {
      $this.ym.a = $this.ym.a || [];
      $this.ym.a.push(arguments);
    };

    this.ym.l = new Date().getTime();
    window.ym = this.ym;

    window.dentolo_quiz_ec = window.dentolo_quiz_ec || [];

    const isAlreadyExist = document.querySelector(`[src="${SRC}"]`);

    if (!isAlreadyExist) {
      const script = document.createElement("script");
      const firstScript = document.getElementsByTagName("script")[0];
      script.async = true;
      script.src = SRC;
      firstScript?.parentNode?.insertBefore(script, firstScript);
      await new Promise<void>(resolve => {
        script.onload = () => resolve();
      });
    }

    window.ym(this.metrika_number, "init", {
      trackLinks: true,
      clickmap: true,
      webvisor: webvisor,
      accurateTrackBounce: true,
      triggerEvent: true,
      ecommerce: "dentolo_quiz_ec"
    });

		this.eventEmitter.emit("init", null);
		this._inited = true;
  }

  public exec(...args:any) {
		if (this.isInited()) return window.ym(this.metrika_number, ...args);
			else {
				return new Promise<any>(resolve => {
					this.eventEmitter.subscribe("init", () => {
						return resolve(window.ym(this.metrika_number, ...args));
					});
				})
			}
  }
}

export const yaMetrikaCounter = new YaMetrikaCounter(appConfig().ya_metrika_counter);